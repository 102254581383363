import { UserType } from '@kijiji/generated/graphql-types'

export const GoogleReviewClickR2S = 'google_review_click_r2s'
export const GoogleReviewClickAboutSeller = 'google_review_click_aboutseller'

const GA_USER_RATING_MAP: Partial<Record<UserType, string>> = {
  [UserType.Admarkt]: 'admarkt_rating',
  [UserType.CasMerchant]: 'business_rating',
  [UserType.Dealer]: 'dealer_rating',
  [UserType.Fsbo]: 'seller_rating',
  [UserType.HeDealer]: 'dealer_rating',
  [UserType.JobsProfessional]: 'business_rating',
  [UserType.Professional]: 'business_rating',
  [UserType.PvDealer]: 'dealer_rating',
  [UserType.ReProfessional]: 'business_rating',
  [UserType.RealEstateAgent]: 'business_rating',
  [UserType.RvDealer]: 'dealer_rating',
  [UserType.Unknown]: 'unknown_rating',
}

/**
 * Maps the web app user type to the analytics rating type.
 *
 * @param accountType - The web app user type.
 * @returns - The corresponding analytics rating type.
 */
export const getAnalyticsRatingType = (accountType: UserType) => {
  const userType = accountType ? accountType : UserType.Unknown

  return GA_USER_RATING_MAP[userType]
}
