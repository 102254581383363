import qs from 'query-string'

import { SIGNIN_RETRIED } from '@/constants/queryParams'
import { type SystemMessageValues, SYSTEM_MESSAGES } from '@/constants/systemMessages'

/** Safe guard that the URL is not been sent back as a string */
export const seoUrlToString = (seoUrl?: URL | null): string => {
  /** Avoid issues for the user with seoURL in case is returned as string instead of URL */
  const redirectUrl: string = seoUrl?.href ? seoUrl.href : typeof seoUrl === 'string' ? seoUrl : ``

  return redirectUrl
}

export const mountDominantCategorySeoUrl = (url: string, categoryId?: number): string => {
  /**
   * The dominant category SEO url needs to append "dc=true" query parameter to the existing search url
   * This parameter should only be used for searches from the search-bar, when the category is 0 (All Categories)
   */
  if (!url || categoryId !== 0) return `${url}`

  const { url: parsedUrl, query } = qs.parseUrl(url)

  const updatedQuery = query ? { ...query, dc: 'true' } : { dc: 'true' }
  return qs.stringifyUrl({ url: parsedUrl, query: updatedQuery })
}

export const removeDomCatFromUrl = (url: string): string => {
  const { url: parsedUrl, query } = qs.parseUrl(url)

  const { dc, ...updatedQuery } = query

  return qs.stringifyUrl({ url: parsedUrl, query: updatedQuery })
}

export const mountTopAdsSeoUrl = (url: string): string => {
  if (!url) return ``

  const { url: parsedUrl, query } = qs.parseUrl(url)

  /**
   * The top ads SEO url needs to append "gpTopAds=y" query parameter to the existing search url
   * This parameter will be manually appended from nwa - no ANVIL call will be  made
   */
  const updatedQuery = query ? { ...query, gpTopAds: 'y' } : { gpTopAds: 'y' }
  return qs.stringifyUrl({ url: parsedUrl, query: updatedQuery })
}

export const sanitizeLogoutUrl = (
  callbackUrl: string,
  appendSystemMessages: SystemMessageValues = {
    ula: 'true',
  }
) => {
  const callbackQuery = callbackUrl.split('?')
  const baseCallbackUrl = callbackQuery[0]
  const currentUrlParams = new URLSearchParams(callbackQuery[1])
  const SystemMessageKeys = Object.keys(SYSTEM_MESSAGES)
  SystemMessageKeys.push('error')
  SystemMessageKeys.push(SIGNIN_RETRIED)

  /**
   * Clean up system messages so that if a user who has just logged in then
   * clicks on Sign Out, they do not find themselves logged out with 2 messages on screen
   * one telling them they've signed out, one telling them they've signed in.
   */
  for (const key of Array.from(currentUrlParams.keys())) {
    if (SystemMessageKeys.includes(key)) {
      currentUrlParams.delete(key)
    }
  }

  for (const key in appendSystemMessages) {
    currentUrlParams.append(key, appendSystemMessages[key])
  }

  const sanitizedCallbackUrl =
    currentUrlParams.toString().length > 0
      ? `${baseCallbackUrl}?${currentUrlParams.toString()}`
      : baseCallbackUrl
  return sanitizedCallbackUrl
}

export const sanitizeLoginUrl = (
  callbackUrl: string,
  systemMessageParams: { [name: string]: string } = {}
) => {
  // Remove system messages
  const callbackQuery = callbackUrl.split('?')
  const baseCallbackUrl = callbackQuery[0].includes(`http`)
    ? callbackQuery[0]
    : `${location.origin}${callbackQuery[0]}`
  const currentUrlParams = new URLSearchParams(callbackQuery[1])
  const SystemMessageKeys = Object.keys(SYSTEM_MESSAGES)
  SystemMessageKeys.push('error')
  SystemMessageKeys.push(SIGNIN_RETRIED)

  /**
   * Clean up system messages so that if a user who has just logged out then
   * clicks on Sign In, they do not find themselves logged in with 2 messages on screen
   * one telling them they've signed out, one telling them they've signed in.
   */
  for (const key of Array.from(currentUrlParams.keys())) {
    if (SystemMessageKeys.includes(key)) {
      currentUrlParams.delete(key)
    }
  }

  /**
   * Some pages (like consumer/register) may call `useSignin`
   * with the actual expectation of having their system message params preserved.
   * This param while not directly preserving the original, provides a method by
   * which they can have such system messages passed through the login process.
   */
  if (Object.keys(systemMessageParams).length > 0) {
    for (const key of Object.keys(systemMessageParams)) {
      currentUrlParams.append(key, systemMessageParams[key])
    }
  }

  if (!currentUrlParams.has('uli')) {
    currentUrlParams.append('uli', 'true')
  }

  currentUrlParams.append('flow', 'signin')

  const sanitizedCallbackUrl =
    currentUrlParams.toString().length > 0
      ? `${baseCallbackUrl}?${currentUrlParams.toString()}`
      : baseCallbackUrl
  return sanitizedCallbackUrl
}

export const mountPaginationUrl = (seoUrl: string, page: number): string => {
  if (!seoUrl) return ``

  const url = new URL(seoUrl)

  const baseUrl = url.origin
  const queryString = url.search

  let parameters = url.pathname.split('/')

  // Remove the parameter if there is already a pagination one in the URL
  parameters = parameters.filter((item) => !item.includes('page-'))

  // Add the pagination parameter to the last position of the list of parameters
  // It should only add "page-N" param if pagination is above 1
  if (page > 1) {
    parameters.splice(parameters.length - 1, 0, `page-${page}`)
  }

  return qs.stringifyUrl({ url: `${baseUrl}${parameters.join('/')}${queryString}` })
}

/**
 * Removes encoded forward slash and replaces it with hyphen
 * @param url Input HREF
 * @returns HREF with '%2F' replaced by '-'
 */
export const replaceEncodedForwardSlash = (url: string) => {
  return typeof url === 'string' ? url.replace(/%2F/g, '-') : ''
}

/**
 * Removes encoded whitespace and replaces it with a + sign, which is how seo-service does it
 * @param url Input HREF
 * @returns HREF with '%20' replaced by '+'
 */
export const replaceEncodedWhitespaceWithPlusSign = (url: string) => {
  return typeof url === 'string' ? url.replace(/%20/g, '+') : ''
}
