export const CATEGORIES = {
  ROOT_CATEGORY_ID: 0,
  BUY_AND_SELL_CATEGORY_ID: 10,
  ART_COLLECTIBLES_CATEGORY_ID: 12,
  BABY_ITEMS_CATEGORY_ID: 253,
  BATHING_CHANGING_CATEGORY_ID: 272,
  CRIBS_CATEGORY_ID: 269,
  FEEDING_HIGH_CHAIRS_CATEGORY_ID: 271,
  GATES_MONITORS_SAFETY_CATEGORY_ID: 273,
  PLAYPENS_SWINGS_SAUCERS_CATEGORY_ID: 268,
  STROLLERS_CARRIERS_CAR_SEATS_CATEGORY_ID: 270,
  TOYS_CATEGORY_ID: 256,
  BIKES_CATEGORY_ID: 644,
  BMX_CATEGORY_ID: 645,
  CLOTHING_SHOES_ACCESSORIES_CATEGORY_ID: 650,
  FRAMES_PARTS_CATEGORY_ID: 649,
  KIDS_CATEGORY_ID: 646,
  MOUNTAIN_CATEGORY_ID: 647,
  ROAD_CATEGORY_ID: 648,
  BOOKS_CATEGORY_ID: 109,
  BUSINESS_INDUSTRIAL_CATEGORY_ID: 29659003,
  CAMERAS_CAMCORDERS_CATEGORY_ID: 103,
  CDS_DVDS_BLURAY_CATEGORY_ID: 104,
  CLOTHING_CATEGORY_ID: 274,
  COSTUMES_CATEGORY_ID: 277,
  KIDS_YOUTH_CATEGORY_ID: 279,
  MENS_CATEGORY_ID: 278,
  COMPUTER_ACCESSORIES_CATEGORY_ID: 128,
  COMPUTERS_CATEGORY_ID: 16,
  ELECTRONICS_CATEGORY_ID: 1529659001,
  GENERAL_ELECTRONICS_CATEGORY_ID: 15,
  FURNITURE_CATEGORY_ID: 235,
  BEDS_MATTRESSES_CATEGORY_ID: 246,
  BOOKCASES_SHELVING_UNITS_CATEGORY_ID: 249,
  CHAIRS_RECLINERS_CATEGORY_ID: 245,
  COFFEE_TABLES_CATEGORY_ID: 241,
  COUCHES_FUTONS_CATEGORY_ID: 238,
  DESKS_CATEGORY_ID: 239,
  DINING_TABLES_AND_SETS_CATEGORY_ID: 243,
  DRESSERS_WARDROBES_CATEGORY_ID: 247,
  HUTCHES_DISPLAY_CABINETS_CATEGORY_ID: 250,
  MULTI_ITEM_CATEGORY_ID: 237,
  OTHER_TABLES_CATEGORY_ID: 244,
  TV_TABLES_ENTERTAINMENT_UNITS_CATEGORY_ID: 242,
  GARAGE_SALES_CATEGORY_ID: 638,
  HEALTH_SPECIAL_NEEDS_CATEGORY_ID: 140,
  HOBBIES_CRAFTS_CATEGORY_ID: 139,
  HOME_INDOOR_CATEGORY_ID: 717,
  BATHWARES_CATEGORY_ID: 718,
  BEDDING_CATEGORY_ID: 719,
  HOME_DECOR_ACCENTS_CATEGORY_ID: 720,
  INDOOR_LIGHTING_FANS_CATEGORY_ID: 721,
  KITCHEN_AND_DINING_WARES_CATEGORY_ID: 722,
  RUGS_CARPETS_RUNNERS_CATEGORY_ID: 723,
  WINDOW_TREATMENTS_CATEGORY_ID: 724,
  HOME_OUTDOOR_CATEGORY_ID: 19,
  BBQS_OUTDOOR_COOKING_CATEGORY_ID: 678,
  DECKS_FENCES_CATEGORY_ID: 679,
  GARAGE_DOORS_OPENERS_CATEGORY_ID: 680,
  HOT_TUBS_POOLS_CATEGORY_ID: 681,
  LAWNMOWERS_LEAF_BLOWERS_CATEGORY_ID: 682,
  OUTDOOR_TOOLS_STORAGE_CATEGORY_ID: 685,
  OUTDOOR_DECOR_CATEGORY_ID: 683,
  OUTDOOR_LIGHTING_CATEGORY_ID: 684,
  PATIO_AND_GARDEN_FURNITURE_CATEGORY_ID: 686,
  PLANTS_FERTILIZER_SOIL_CATEGORY_ID: 687,
  SNOWBLOWERS_CATEGORY_ID: 688,
  HOME_APPLIANCES_CATEGORY_ID: 107,
  COFFEE_MAKERS_CATEGORY_ID: 689,
  DISHWASHERS_CATEGORY_ID: 690,
  FREEZERS_CATEGORY_ID: 691,
  HEATERS_HUMIDIFIERS_DEHUMIDIFIERS_CATEGORY_ID: 692,
  IRONS_GARMENT_STEAMERS_CATEGORY_ID: 693,
  MICROWAVES_COOKERS_CATEGORY_ID: 694,
  PROCESSORS_BLENDERS_JUICERS_CATEGORY_ID: 695,
  REFRIGERATORS_CATEGORY_ID: 696,
  STOVES_OVENS_RANGES_CATEGORY_ID: 697,
  TOASTERS_TOASTER_OVENS_CATEGORY_ID: 698,
  VACUUMS_CATEGORY_ID: 699,
  WASHERS_DRYERS_CATEGORY_ID: 700,
  HOME_RENOVATION_MATERIALS_CATEGORY_ID: 727,
  CABINETS_COUNTERTOPS_CATEGORY_ID: 728,
  ELECTRICAL_CATEGORY_ID: 729,
  FLOORS_WALLS_CATEGORY_ID: 730,
  HARDWARE_NAILS_SCREWS_CATEGORY_ID: 731,
  HEATING_COOLING_AIR_CATEGORY_ID: 732,
  PAINTING_PAINT_SUPPLIES_CATEGORY_ID: 733,
  PLUMBING_SINKS_TOILETS_SHOWERS_CATEGORY_ID: 734,
  WINDOWS_DOORS_TRIM_CATEGORY_ID: 736,
  JEWELLERY_WATCHES_CATEGORY_ID: 133,
  MUSICAL_INSTRUMENTS_CATEGORY_ID: 17,
  AMPS_PEDALS_CATEGORY_ID: 610,
  BRASS_CATEGORY_ID: 611,
  DRUMS_PERCUSSION_CATEGORY_ID: 612,
  GUITARS_CATEGORY_ID: 613,
  PIANOS_KEYBOARDS_CATEGORY_ID: 614,
  PRO_AUDIO_RECORDING_EQUIP_CATEGORY_ID: 615,
  STRING_CATEGORY_ID: 616,
  WOODWIND_CATEGORY_ID: 617,
  PHONES_CATEGORY_ID: 132,
  SPORTING_GOODS_EXERCISE_CATEGORY_ID: 111,
  BASEBALL_SOFTBALL_CATEGORY_ID: 652,
  BASKETBALL_CATEGORY_ID: 653,
  CURLING_CATEGORY_ID: 654,
  EXERCISE_EQUIPMENT_CATEGORY_ID: 655,
  FISHING_CAMPING_OUTDOORS_CATEGORY_ID: 656,
  FOOTBALL_CATEGORY_ID: 657,
  GOLF_CATEGORY_ID: 658,
  HOCKEY_CATEGORY_ID: 659,
  LACROSSE_CATEGORY_ID: 660,
  PAINTBALL_CATEGORY_ID: 661,
  SKATEBOARD_CATEGORY_ID: 663,
  SKATES_BLADES_CATEGORY_ID: 662,
  SKI_CATEGORY_ID: 664,
  SNOWBOARD_CATEGORY_ID: 665,
  SOCCER_CATEGORY_ID: 666,
  TENNIS_RACQUET_CATEGORY_ID: 667,
  WATER_SPORTS_CATEGORY_ID: 668,
  TICKETS_CATEGORY_ID: 14,
  TOOLS_CATEGORY_ID: 110,
  HAND_TOOLS_CATEGORY_ID: 702,
  LADDERS_SCAFFOLDING_CATEGORY_ID: 705,
  POWER_TOOLS_CATEGORY_ID: 703,
  TOOL_STORAGE_BENCHES_CATEGORY_ID: 704,
  TOYS_GAMES_CATEGORY_ID: 108,
  VIDEO_GAMES_CONSOLES_CATEGORY_ID: 141,
  NINTENDO_DS_CATEGORY_ID: 619,
  NINTENDO_WII_CATEGORY_ID: 626,
  OLDER_GENERATION_CATEGORY_ID: 623,
  PC_GAMES_CATEGORY_ID: 624,
  SONY_PLAYSTATION_2_CATEGORY_ID: 620,
  SONY_PLAYSTATION_3_CATEGORY_ID: 627,
  SONY_PSP_CATEGORY_ID: 621,
  XBOX_360_CATEGORY_ID: 622,
  CARS_AND_VEHICLES_CATEGORY_ID: 27,
  ATVS_SNOWMOBILES_CATEGORY_ID: 171,
  ATVS_SNOWMOBILES_OTHERS_CATEGORY_ID: 315,
  ATV_PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID: 312,
  ATVS_CATEGORY_ID: 311,
  SNOWMOBILES_CATEGORY_ID: 313,
  SNOWMOBILES_PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID: 314,
  AUTO_PARTS_TIRES_CATEGORY_ID: 31,
  AUDIO_GPS_CATEGORY_ID: 316,
  AUTO_BODY_PARTS_CATEGORY_ID: 317,
  ENGINE_ENGINE_PARTS_CATEGORY_ID: 318,
  OTHER_PARTS_ACCESSORIES_CATEGORY_ID: 321,
  TIRES_RIMS_CATEGORY_ID: 320,
  TRANSMISSION_DRIVE_TRAIN_CATEGORY_ID: 319,
  AUTOMOTIVE_SERVICES_CATEGORY_ID: 142,
  AUTO_INSURANCE_FINANCING_CATEGORY_ID: 325,
  DETAILING_CLEANING_CATEGORY_ID: 323,
  REPAIRS_MAINTENANCE_CATEGORY_ID: 322,
  TOWING_SCRAP_REMOVAL_CATEGORY_ID: 324,
  BOATS_WATERCRAFT_CATEGORY_ID: 29,
  BOATS_WATERCRAFT_OTHERS_CATEGORY_ID: 332,
  CANOES_KAYAKS_PADDLE_CATEGORY_ID: 329,
  PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID: 331,
  PERSONAL_WATERCRAFT_CATEGORY_ID: 330,
  POWERBOATS_MOTORBOATS_CATEGORY_ID: 327,
  SAILBOATS_CATEGORY_ID: 328,
  CARS_AND_TRUCKS_CATEGORY_ID: 174,
  CLASSIC_CARS_CATEGORY_ID: 122,
  HEAVY_EQUIPMENT_CATEGORY_ID: 301,
  HEAVY_EQUIPMENT_MACHINERY_CATEGORY_ID: 340,
  HEAVY_EQUIPMENT_PARTS_ACCESSORIES_CATEGORY_ID: 342,
  FARMING_EQUIPMENT_CATEGORY_ID: 341,
  HEAVY_TRUCKS_CATEGORY_ID: 339,
  MOTORCYCLES_CATEGORY_ID: 30,
  DIRT_BIKES_MOTOCROSS_CATEGORY_ID: 307,
  MOTORCYCLE_PARTS_ACCESSORIES_CATEGORY_ID: 309,
  SCOOTERS_POCKET_BIKES_CATEGORY_ID: 308,
  SPORT_BIKES_CATEGORY_ID: 304,
  SPORT_TOURING_CATEGORY_ID: 305,
  STREET_CRUISERS_CHOPPERS_CATEGORY_ID: 303,
  TOURING_CATEGORY_ID: 306,
  RVS_CAMPERS_TRAILERS_CATEGORY_ID: 172,
  RVS_CAMPERS_TRAILERS_OTHERS_CATEGORY_ID: 338,
  RVS_PARTS_ACCESSORIES_CATEGORY_ID: 337,
  CARGO_UTILITY_TRAILERS_CATEGORY_ID: 336,
  PARK_MODELS_CATEGORY_ID: 335,
  RVS_MOTORHOMES_CATEGORY_ID: 333,
  TRAVEL_TRAILERS_CAMPERS_CATEGORY_ID: 334,
  COMMUNITY_CATEGORY_ID: 1,
  OTHER_COMMUNITY_CATEGORY_ID: 9,
  ACTIVITIES_GROUPS_CATEGORY_ID: 7,
  ARTISTS_MUSICIANS_CATEGORY_ID: 2,
  CLASSES_LESSONS_CATEGORY_ID: 4,
  DISCUSSIONS_CATEGORY_ID: 637,
  EVENTS_CATEGORY_ID: 289,
  FRIENDSHIP_NETWORKING_CATEGORY_ID: 634,
  LONG_LOST_RELATIONSHIPS_CATEGORY_ID: 635,
  MISSED_CONNECTIONS_CATEGORY_ID: 636,
  RIDESHARE_CATEGORY_ID: 5,
  VOLUNTEERS_CATEGORY_ID: 3,
  JOBS_CATEGORY_ID: 45,
  JOBS_ACCOUNTING_MANAGEMENT_CATEGORY_ID: 58,
  JOBS_CHILD_CARE_CATEGORY_ID: 47,
  JOBS_BAR_FOOD_HOSPITALITY_CATEGORY_ID: 60,
  JOBS_CLEANING_HOUSEKEEPER_CATEGORY_ID: 146,
  JOBS_CONSTRUCTION_TRADES_CATEGORY_ID: 50,
  JOBS_CUSTOMER_SERVICE_CATEGORY_ID: 147,
  JOBS_DRIVER_SECURITY_CATEGORY_ID: 148,
  JOBS_GENERAL_LABOUR_CATEGORY_ID: 149,
  JOBS_HAIR_STYLIST_SALON_CATEGORY_ID: 150,
  JOBS_GRAPHIC_WEB_DESIGN_CATEGORY_ID: 152,
  JOBS_OFFICE_MANAGER_RECEPTIONIST_CATEGORY_ID: 46,
  JOBS_PART_TIME_STUDENTS_CATEGORY_ID: 59,
  JOBS_PROGRAMMERS_COMPUTERS_CATEGORY_ID: 54,
  JOBS_SALES_RETAIL_SALES_CATEGORY_ID: 61,
  JOBS_TV_MEDIA_FASHION_CATEGORY_ID: 55,
  JOBS_OTHER_CATEGORY_ID: 62,
  JOBS_HEALTHCARE_CATEGORY_ID: 898,
  JOBS_CANNABIS_CATEGORY_ID: 420,
  PETS_CATEGORY_ID: 112,
  PETS_ACCESSORIES_CATEGORY_ID: 115,
  ANIMAL_PET_SERVICES_CATEGORY_ID: 143,
  BIRDS_FOR_SALE_CATEGORY_ID: 135,
  CATS_KITTENS_FOR_SALE_CATEGORY_ID: 125,
  DOGS_PUPPIES_FOR_SALE_CATEGORY_ID: 126,
  LIVESTOCK_CATEGORY_ID: 217,
  OTHER_PETS_FOR_SALE_CATEGORY_ID: 114,
  TO_GIVE_OR_DONATE_CATEGORY_ID: 113,
  PETS_OTHER_CATEGORY_ID: 127,
  REAL_ESTATE_CATEGORY_ID: 34,
  APARTMENTS_CONDOS_CATEGORY_ID: 37,
  COMMERCIAL_OFFICE_SPACE_CATEGORY_ID: 40,
  CONDOS_FOR_SALE_CATEGORY_ID: 643,
  REAL_ESTATE_FOR_SALE_CATEGORY_ID: 30353001,
  REAL_ESTATE_FOR_RENT_CATEGORY_ID: 30349001,
  HOUSE_RENTAL_CATEGORY_ID: 43,
  HOUSES_FOR_SALE_CATEGORY_ID: 35,
  LAND_FOR_SALE_CATEGORY_ID: 641,
  REAL_ESTATE_SERVICES_CATEGORY_ID: 170,
  ROOM_RENTAL_ROOMMATES_CATEGORY_ID: 36,
  SHORT_TERM_RENTALS_CATEGORY_ID: 42,
  STORAGE_PARKING_CATEGORY_ID: 39,
  DEPRECATED_VACATION_RENTALS_CATEGORY_ID: 642,
  REAL_ESTATE_OTHER: 44,
  RESUMES_CHILD_CARE_CATEGORY_ID: 225,
  SERVICES_CATEGORY_ID: 72,
  CHILDCARE_NANNY_CATEGORY_ID: 84,
  CLEANERS_CLEANING_CATEGORY_ID: 160,
  COMPUTER_CATEGORY_ID: 80,
  ENTERTAINMENT_CATEGORY_ID: 165,
  FINANCIAL_LEGAL_CATEGORY_ID: 131,
  FITNESS_PERSONAL_TRAINER_CATEGORY_ID: 83,
  HEALTH_BEAUTY_CATEGORY_ID: 166,
  MOVING_STORAGE_CATEGORY_ID: 144,
  MUSIC_LESSONS_CATEGORY_ID: 86,
  PHOTOGRAPHY_VIDEO_CATEGORY_ID: 168,
  SKILLED_TRADES_CATEGORY_ID: 76,
  APPLIANCE_REPAIR_INSTALLATION_CATEGORY_ID: 738,
  BRICK_MASONRY_CONCRETE_CATEGORY_ID: 739,
  CARPENTRY_CROWN_MOULDING_TRIMWORK_CATEGORY_ID: 740,
  DRYWALL_STUCCO_REMOVAL_CATEGORY_ID: 741,
  ELECTRICIAN_CATEGORY_ID: 742,
  EXCAVATION_DEMOLITION_WATERPROOFING_CATEGORY_ID: 743,
  FENCE_DECK_RAILING_SIDING_CATEGORY_ID: 744,
  FLOORING_CATEGORY_ID: 745,
  GARAGE_DOOR_CATEGORY_ID: 746,
  HEATING_VENTILATION_AIR_CONDITIONING_CATEGORY_ID: 747,
  INSULATION_CATEGORY_ID: 748,
  INTERLOCK_PAVING_DRIVEWAYS_CATEGORY_ID: 749,
  LAWN_TREE_MAINTENANCE_EAVESTROUGH_CATEGORY_ID: 750,
  PHONE_NETWORK_CABLE_HOME_WIRING_CATEGORY_ID: 751,
  PLUMBING_CATEGORY_ID: 752,
  RENOVATIONS_GENERAL_CONTRACTING_HANDYMAN_CATEGORY_ID: 753,
  SNOW_REMOVAL_PROPERTY_MAINTENANCE_CATEGORY_ID: 755,
  WELDING_CATEGORY_ID: 756,
  WINDOWS_DOORS_CATEGORY_ID: 757,
  TRAVEL_VACATIONS_CATEGORY_ID: 302,
  TUTORS_LANGUAGES_CATEGORY_ID: 169,
  PAINTERS_PAINTING_CATEGORY_ID: 759,
  IPODS_MP3_HEADPHONES_CATEGORY_ID: 767,
  IPODS_MP3_CATEGORY_ID: 768,
  IPOD_MP3_ACCESSORIES_CATEGORY_ID: 769,
  HEADPHONES_CATEGORY_ID: 770,
  CELL_PHONES_CATEGORY_ID: 760,
  CELL_PHONE_ACCESSORIES_CATEGORY_ID: 761,
  CELL_PHONE_SERVICES_CATEGORY_ID: 762,
  HOME_PHONES_ANSWERING_MACHINES_CATEGORY_ID: 765,
  DESKTOP_COMPUTERS_CATEGORY_ID: 772,
  LAPTOPS_CATEGORY_ID: 773,
  SERVERS_CATEGORY_ID: 774,
  CABLES_AND_CONNECTORS_CATEGORY_ID: 777,
  FLASH_MEMORY_USB_STICKS_CATEGORY_ID: 778,
  LAPTOP_ACCESSORIES_CATEGORY_ID: 780,
  MICE_KEYBOARDS_WEBCAM_CATEGORY_ID: 781,
  MONITORS_CATEGORY_ID: 782,
  NETWORKING_CATEGORY_ID: 783,
  PRINTERS_SCANNERS_FAX_CATEGORY_ID: 784,
  SERVICES_TRAINING_REPAIR_CATEGORY_ID: 785,
  SOFTWARE_CATEGORY_ID: 786,
  SPEAKERS_HEADSETS_MICS_CATEGORY_ID: 787,
  SYSTEM_COMPONENTS_CATEGORY_ID: 788,
  VACATION_RENTALS_CATEGORY_ID: 800,
  VACATION_RENTALS_CANADA_CATEGORY_ID: 801,
  VACATION_RENTALS_USA_CATEGORY_ID: 802,
  VACATION_RENTALS_CARIBBEAN_CATEGORY_ID: 803,
  VACATION_RENTALS_CARIBBEAN_BARBADOS_CATEGORY_ID: 873,
  VACATION_RENTALS_MEXICO_CATEGORY_ID: 804,
  VACATION_RENTALS_OTHER_COUNTRIES_CATEGORY_ID: 805,
  BUY_AND_SELL_AUDIO_CATEGORY_ID: 767,
  BUY_AND_SELL_OTHER_CATEGORY_ID: 26,
  BUY_AND_SELL_TV_VIDEO_CATEGORY_ID: 15093001,
  GIVE_AWAY_CATEGORY_ID: 17220001,
  PETS_FISH_FOR_REHOMING_CATEGORY_ID: 14654003,
  PETS_REPTILES_AMPHIBIANS_FOR_REHOMING_CATEGORY_ID: 14654004,
  PETS_SMALL_ANIMALS_FOR_REHOMING_CATEGORY_ID: 14654005,
  PETS_HORSES_FOR_REHOMING_CATEGORY_ID: 14996001,
  PETS_EQUESTRIAN_LIVESTOCK_ACCESSORIES_CATEGORY_ID: 14996002,
  PETS_LOST_AND_FOUND_CATEGORY_ID: 791,
  PETS_REGISTERED_SHELTER: 14996003,
  WOMEN_SHOES_CATEGORY_ID: 286,
  MEN_SHOES_CATEGORY_ID: 15117001,
}

export const L1_CATEGORIES = {
  BUY_AND_SELL: CATEGORIES.BUY_AND_SELL_CATEGORY_ID,
  CARS_AND_VEHICLES: CATEGORIES.CARS_AND_VEHICLES_CATEGORY_ID,
  REAL_ESTATE: CATEGORIES.REAL_ESTATE_CATEGORY_ID,
  JOBS: CATEGORIES.JOBS_CATEGORY_ID,
  SERVICES: CATEGORIES.SERVICES_CATEGORY_ID,
  PETS: CATEGORIES.PETS_CATEGORY_ID,
  VACATION_RENTALS: CATEGORIES.VACATION_RENTALS_CATEGORY_ID,
  COMMUNITY: CATEGORIES.COMMUNITY_CATEGORY_ID,
}

export const BUY_SELL_L1 = 10
export const BUY_SELL_L2 = [
  12, 274, 717, 108, 109, 26, 235, 111, 104, 253, 29659001, 110, 107, 139, 128, 19, 727, 133, 141,
  140, 132, 17, 29659003, 767, 16, 644, 103, 15093001, 17220001, 638, 14, 931,
]
export const BUY_SELL_L3 = [
  689, 690, 691, 692, 693, 694, 695, 696, 697, 698, 699, 700, 701, 236, 237, 238, 239, 241, 242,
  243, 244, 245, 246, 247, 249, 250, 14970005, 14970006, 14970007, 14970001, 14970002, 14970003,
  14970004, 705, 715, 702, 703, 704, 652, 653, 654, 655, 656, 657, 658, 659, 660, 661, 662, 663,
  664, 665, 666, 667, 668, 669, 15093003, 15093002, 257, 258, 259, 260, 261, 262, 263, 264, 265,
  266, 267, 268, 269, 270, 271, 272, 273, 254, 255, 256, 29659002, 15, 769, 770, 771, 14922002,
  14922001, 768, 145, 29659005, 29659006, 29659004, 777, 778, 780, 781, 782, 783, 784, 785, 786,
  787, 788, 789, 790, 760, 761, 762, 765, 766, 645, 646, 647, 15096001, 648, 15096002, 649, 650,
  651, 14654001, 33035001, 619, 621, 622, 623, 14654002, 39730001, 624, 39730002, 625, 626, 627,
  792, 793, 721, 722, 723, 724, 725, 15058002, 15058001, 15058003, 718, 719, 720, 88888002, 772,
  773, 774, 776, 29324001, 610, 14922003, 611, 612, 613, 614, 615, 616, 617, 618, 15117001, 275,
  276, 277, 278, 279, 280, 281, 282, 283, 284, 285, 286, 287, 678, 726, 679, 680, 681, 682, 683,
  684, 685, 686, 687, 688, 737, 728, 729, 730, 731, 732, 733, 734, 735, 736,
]

export const CARS_VEHICLES_L1 = 27
export const CARS_VEHICLES_L2 = [33, 31, 301, 30, 29, 174, 172, 171, 142, 122]
export const CARS_VEHICLES_L3 = [
  311, 313, 315, 338, 333, 334, 335, 336, 327, 328, 329, 330, 332, 339, 340, 341, 343, 305, 306,
  307, 308, 310, 303, 304, 322, 323, 324, 325, 326, 321, 337, 309, 342, 312, 314, 331, 316, 317,
  318, 319, 320,
]
export const CARS_VEHICLES_CATEGORIES = {
  VEHICLE_PARTS: CATEGORIES.AUTO_PARTS_TIRES_CATEGORY_ID,
  RVS_CAMPERS_TRAILERS: CATEGORIES.RVS_CAMPERS_TRAILERS_CATEGORY_ID,
  CARS_TRUCKS: CATEGORIES.CARS_AND_TRUCKS_CATEGORY_ID,
  MOTORCYCLES: CATEGORIES.MOTORCYCLES_CATEGORY_ID,
  HEAVY_EQUIPMENT: CATEGORIES.HEAVY_EQUIPMENT_CATEGORY_ID,
  BOATS_WATERCRAFT: CATEGORIES.BOATS_WATERCRAFT_CATEGORY_ID,
  OTHER: 33,
  ATVS_SNOWMOBILES: CATEGORIES.ATVS_SNOWMOBILES_CATEGORY_ID,
  FARMING_EQUIPMENT: CATEGORIES.FARMING_EQUIPMENT_CATEGORY_ID,
  CLASSIC_CARS: CATEGORIES.CLASSIC_CARS_CATEGORY_ID,
  AUTOMOTIVE_SERVICES: CATEGORIES.AUTOMOTIVE_SERVICES_CATEGORY_ID,
} as const

export const AUTO_PARTS_TIRES_ACCESSORIES_CATEGORIES = [
  // parent category
  CATEGORIES.AUTO_PARTS_TIRES_CATEGORY_ID,
  // subcategories
  CATEGORIES.ATV_PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.AUDIO_GPS_CATEGORY_ID,
  CATEGORIES.AUTO_BODY_PARTS_CATEGORY_ID,
  CATEGORIES.ENGINE_ENGINE_PARTS_CATEGORY_ID,
  CATEGORIES.HEAVY_EQUIPMENT_PARTS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.MOTORCYCLE_PARTS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.OTHER_PARTS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.RVS_PARTS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.SNOWMOBILES_PARTS_TRAILERS_ACCESSORIES_CATEGORY_ID,
  CATEGORIES.TIRES_RIMS_CATEGORY_ID,
  CATEGORIES.TRANSMISSION_DRIVE_TRAIN_CATEGORY_ID,
]

export const MOTORCYCLES_CATEGORIES = [
  // parent category
  CATEGORIES.MOTORCYCLES_CATEGORY_ID,
  // subcategories
  CATEGORIES.STREET_CRUISERS_CHOPPERS_CATEGORY_ID,
  CATEGORIES.SPORT_BIKES_CATEGORY_ID,
  CATEGORIES.SPORT_TOURING_CATEGORY_ID,
  CATEGORIES.TOURING_CATEGORY_ID,
  CATEGORIES.DIRT_BIKES_MOTOCROSS_CATEGORY_ID,
  CATEGORIES.SCOOTERS_POCKET_BIKES_CATEGORY_ID,
  310, // other
]

export const RVS_CAMPERS_TRAILERS_CATEGORIES = [
  // parent category
  CATEGORIES.RVS_CAMPERS_TRAILERS_CATEGORY_ID,
  // subcategories
  CATEGORIES.CARGO_UTILITY_TRAILERS_CATEGORY_ID,
  CATEGORIES.TRAVEL_TRAILERS_CAMPERS_CATEGORY_ID,
  CATEGORIES.RVS_MOTORHOMES_CATEGORY_ID,
  CATEGORIES.PARK_MODELS_CATEGORY_ID,
  CATEGORIES.RVS_CAMPERS_TRAILERS_OTHERS_CATEGORY_ID,
]

export const BOATS_WATERCRAFT_CATEGORIES = [
  // parent category
  CATEGORIES.BOATS_WATERCRAFT_CATEGORY_ID,
  // subcategories
  CATEGORIES.CANOES_KAYAKS_PADDLE_CATEGORY_ID,
  CATEGORIES.POWERBOATS_MOTORBOATS_CATEGORY_ID,
  CATEGORIES.SAILBOATS_CATEGORY_ID,
  CATEGORIES.PERSONAL_WATERCRAFT_CATEGORY_ID,
  CATEGORIES.BOATS_WATERCRAFT_OTHERS_CATEGORY_ID,
]

export const ATVS_SNOWMOBILES_CATEGORIES = [
  // parent category
  CATEGORIES.ATVS_SNOWMOBILES_CATEGORY_ID,
  // subcategories
  CATEGORIES.ATVS_CATEGORY_ID,
  CATEGORIES.SNOWMOBILES_CATEGORY_ID,
  CATEGORIES.ATVS_SNOWMOBILES_OTHERS_CATEGORY_ID,
]

export const AUTOMOTIVE_SERVICES_CATEGORIES = [
  // parent category
  CATEGORIES.AUTOMOTIVE_SERVICES_CATEGORY_ID,
  // subcategories
  CATEGORIES.AUTO_INSURANCE_FINANCING_CATEGORY_ID,
  CATEGORIES.DETAILING_CLEANING_CATEGORY_ID,
  CATEGORIES.REPAIRS_MAINTENANCE_CATEGORY_ID,
  CATEGORIES.TOWING_SCRAP_REMOVAL_CATEGORY_ID,
  326, // other
]

export const AUTOS_NON_VEHICLES_CATEGORIES = [
  ...AUTOMOTIVE_SERVICES_CATEGORIES,
  ...AUTO_PARTS_TIRES_ACCESSORIES_CATEGORIES,
  CARS_VEHICLES_CATEGORIES.OTHER,
  CATEGORIES.ATVS_SNOWMOBILES_OTHERS_CATEGORY_ID,
  CATEGORIES.BOATS_WATERCRAFT_OTHERS_CATEGORY_ID,
  CATEGORIES.RVS_CAMPERS_TRAILERS_OTHERS_CATEGORY_ID,
]

export const COMMUNITY_L1 = 1
export const COMMUNITY_L2 = [4, 5, 9, 2, 7, 289, 634, 3, 120, 15102001, 635, 636]
export const COMMUNITY_L3: number[] = []

export const JOBS_L1 = 45
export const JOBS_L2 = [
  50, 149, 148, 62, 47, 60, 46, 58, 898, 146, 61, 54, 147, 59, 150, 152, 55, 420,
]
export const JOBS_L3: number[] = []

export const PETS_L1 = 112
export const PETS_L2 = [
  114, 115, 125, 126, 127, 135, 143, 14654003, 14654004, 14654005, 14996001, 14996002, 14996003,
  217, 791,
]
export const PETS_L3: number[] = []

export const REAL_ESTATE_L1 = 34
export const REAL_ESTATE_L2 = [30349001, 170, 30353001]
export const REAL_ESTATE_L3 = [36, 37, 39, 40, 42, 641, 35, 643, 44]

export const SERVICES_L1 = 72
export const SERVICES_L2 = [
  76, 87, 160, 166, 169, 168, 84, 165, 15214001, 79, 86, 83, 144, 131, 302,
]
export const SERVICES_L3 = [
  1661, 1662, 738, 739, 740, 741, 742, 743, 744, 745, 746, 747, 748, 749, 750, 751, 752, 753, 754,
  755, 756, 757, 758, 759,
]

export const VACATION_RENTALS_L1 = 800
export const VACATION_RENTALS_L2 = [801, 802, 804, 805, 803]
export const VACATION_RENTALS_L3 = [
  806, 807, 808, 809, 810, 811, 812, 813, 814, 815, 816, 865, 866, 867, 868, 817, 818, 819, 820,
  821, 822, 823, 824, 825, 826, 827, 828, 829, 830, 831, 832, 833, 834, 835, 836, 837, 838, 839,
  840, 841, 842, 843, 844, 845, 846, 847, 848, 849, 850, 851, 852, 853, 854, 855, 856, 857, 858,
  859, 860, 861, 862, 863, 864, 869, 870, 871, 872, 873, 874, 875, 876, 877, 878, 879, 880, 881,
  882, 883, 884, 885, 886, 887, 888, 889, 890, 891, 892, 893, 894, 895, 896, 897,
]

export const VEHICLES_PART_ACCESSORIES_CATEGORIES = [
  31, 309, 312, 314, 316, 317, 318, 319, 320, 321, 331, 337, 342,
]

export const SKILL_TRADE_CATEGORIES = [
  76, 742, 738, 739, 740, 741, 742, 743, 757, 744, 745, 746, 747, 748, 749, 750, 759, 751, 752, 753,
  754, 755, 756, 757, 758,
]

export const FURNITURE_CATEGORY = 235

// Category Levels
export const L1Categories = [
  BUY_SELL_L1,
  CARS_VEHICLES_L1,
  REAL_ESTATE_L1,
  JOBS_L1,
  SERVICES_L1,
  PETS_L1,
  COMMUNITY_L1,
  VACATION_RENTALS_L1,
]

export const L2Categories = [
  ...BUY_SELL_L2,
  ...CARS_VEHICLES_L2,
  ...REAL_ESTATE_L2,
  ...JOBS_L2,
  ...SERVICES_L2,
  ...PETS_L2,
  ...COMMUNITY_L2,
  ...VACATION_RENTALS_L2,
]
