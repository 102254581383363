import CloseIcon from '@kijiji/icons/src/icons/Close'
import { HTMLAttributes } from 'react'

import { StyledModalCloseButton } from '@/ui/molecules/modal/styled'

type ModalCloseButtonProps = HTMLAttributes<HTMLButtonElement> & {
  isDarkModal?: boolean
  hasTitle?: boolean
}

export const ModalCloseButton = ({
  isDarkModal,
  hasTitle,
  ...props
}: ModalCloseButtonProps) => {
  return (
    <StyledModalCloseButton
      type="button"
      isDarkModal={isDarkModal}
      hasTitle={hasTitle}
      {...props}
    >
      <CloseIcon aria-hidden />
    </StyledModalCloseButton>
  )
}
