export const normalizeErrorContext = (error: Error) => {
  let extra: { [key: string]: string } | undefined
  let normalizedMessage = error.message

  // Handling for SyntaxError (JSON parsing errors)
  if (error.name === 'SyntaxError' && error.message) {
    const [, token = '[UNKNOWN]', position = '[UNKNOWN]'] =
      /Unexpected token (\S+).*at position (\d+)/.exec(error.message) || []

    // Normalize message for consistent fingerprinting
    normalizedMessage = normalizedMessage
      .replace(/at position \d+/, 'at position [IGNORED]')
      .replace(/Unexpected token (\S+)/, 'Unexpected token [IGNORED]')

    extra = { token, position }
  }

  return { name: error.name, message: normalizedMessage, extra }
}
