const HttpBackend = require('i18next-http-backend/cjs')
const fetch = require('node-fetch')
const path = require('path')
const {
  generateLocalePath,
  generateLocalePathFromAssetPrefix,
} = require('./generate-locale-prefix.js')

const isDevelopment = process.env.NODE_ENV === 'development' || process.env.ENV_NAME === 'local'

module.exports = {
  i18n: {
    defaultLocale: 'en',
    localeDetection: false,
    locales: ['en', 'fr'],
  },
  fallbackLng: {
    default: ['en'],
    fr: ['fr'],
  },
  localePath:
    typeof window === 'undefined' ? require('path').resolve('./public/locales') : '/locales',
  localeStructure: '{{lng}}-CA/{{ns}}',
  use: isDevelopment || process.env.CI === 'true' ? [] : [HttpBackend],
  backend: {
    loadPath: (lng, ns) => {
      if (typeof window !== 'undefined') {
        return generateLocalePathFromAssetPrefix(process.env.ASSET_PREFIX, lng, ns)
      }

      // Server-side context,
      if (
        process.env.NODE_ENV === 'development' ||
        process.env.CI === 'true' ||
        process.env.ENV_NAME === 'local'
      ) {
        // Local file system path in development
        return path.resolve(`./public/locales/${lng}-CA/${ns}.json`)
      } else {
        // URL generation for staging, production, etc.
        return generateLocalePath(process.env.ENV_NAME, lng, ns)
      }
    },
    requestOptions: {
      cache: 'default',
      mode: 'cors',
    },
    load: (url, options, callback) => {
      fetch(url, options)
        .then((response) => {
          if (!response.ok) throw new Error('Network response was not ok')
          return response.json()
        })
        .then((data) => {
          callback(null, data)
        })
        .catch(() => {
          const fallbackPath = path.join(
            process.cwd(),
            `./public/locales/${options.lng}-CA/${options.ns}.json`
          )
          const fallbackUrl = `file://${fallbackPath}`
          fetch(fallbackUrl, options)
            .then((response) => response.json())
            .then((data) => callback(null, data))
            .catch((err) => callback(err, null))
        })
    },
  },
  ns: [
    '404',
    'autocanada',
    'common',
    'cpo',
    'favourites',
    'feedback',
    'forgot_password',
    'global_footer',
    'global_header',
    'home',
    'landing_page',
    'listing',
    'login',
    'mobile_link',
    'register',
    'reset_password',
    'routes',
    'srp',
    'vip',
    'request_viewing',
  ],
  preload: ['en', 'fr'],
  react: {
    useSuspense: false,
  },
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  serializeConfig: false,
}
