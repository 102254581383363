export type SpacingKeys = keyof typeof spacing

export const spacing = {
  /**
   * 0.4rem (mini < defaultSmall)
   */
  mini: '0.4rem',

  /**
   * 0.8rem (mini < defaultSmall < default)
   */
  defaultSmall: '0.8rem',

  /**
   * 1.6rem (defaultSmall < default < large)
   */
  default: '1.6rem',

  /**
   * 2.4rem (default < large < xLarge)
   */
  large: '2.4rem',

  /**
   * 3.2rem (large < xLarge < husky)
   */
  xLarge: '3.2rem',

  /**
   * 4rem (xLarge < husky < giant)
   */
  husky: '4rem',

  /**
   * 5.2rem (husky < giant)
   */
  giant: '5.2rem',
}
