import { captureException, captureMessage } from '@sentry/nextjs'
import { type ScopeContext, type SeverityLevel } from '@sentry/types'

import { normalizeErrorContext } from '@/lib/sentry/normalizeErrorContext'

export const sendToLogger = (
  err: unknown,
  errorContext?: Partial<ScopeContext>,
  logLevel: SeverityLevel = 'error'
) => {
  // Log error to console in development mode for easier debugging
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.log(err)
  }

  const fingerprint: string[] = errorContext?.fingerprint || []
  let extra = errorContext?.extra

  if (err && err instanceof Error) {
    const { name, message, extra: normalizedExtra } = normalizeErrorContext(err)

    fingerprint.push(name)
    fingerprint.push(message)

    if (normalizedExtra) {
      extra = { ...extra, ...normalizedExtra }
    }
  } else if (err && typeof err === 'string') {
    fingerprint.push(err)
  }

  return captureException(err, {
    ...errorContext,
    ...(extra && { extra }),
    level: logLevel,
    fingerprint,
  })
}

export const logger = {
  captureMessage: (message: string, captureContext: Partial<ScopeContext> | SeverityLevel) =>
    captureMessage(message, captureContext),
}

export const cleanupErrorForSentry = (error: Error, name?: string) => {
  const cleanedUpError = new Error()
  cleanedUpError.message = error.message
  cleanedUpError.name = name ?? error.name
  cleanedUpError.stack = error.stack
  return cleanedUpError
}
