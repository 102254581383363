import { ThemeProps } from '@kijiji/theme'
import { FC, HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { ResponsiveProp, StyledAs } from '@/ui/typings/helpers'

import { BodyTextBase } from './styled'

export type BodyTextSize = keyof ThemeProps['typography']['body']

export type BodyTextProps = {
  /**
   * The type of element you want text to be rendered in
   * @default 'p'
   */
  as?: StyledAs
  /**
   * The color of the text
   * @default 'inherit'
   */
  color?: string
  /**
   * Defines font weight of text
   * @default 'regular'
   */
  weight?: keyof ThemeProps['fontWeight']
  /**
   * The size of the text
   * @default 'medium'
   */
  size?: ResponsiveProp<BodyTextSize>
  /**
   * The alignment of the text
   * @default 'start'
   */
  textAlign?: string
} & HTMLAttributes<HTMLElement>

export const BodyText: FC<BodyTextProps> = ({
  as = 'p',
  color,
  size = 'medium',
  textAlign = 'start',
  weight = 'regular',
  ...rest
}: BodyTextProps) => {
  const { colors } = useTheme()
  return (
    <BodyTextBase
      $color={color ?? colors.grey.primary}
      $weight={weight}
      $size={size}
      $textAlign={textAlign}
      as={as}
      {...rest}
    />
  )
}
