import { type LocationArea } from '@kijiji/generated/graphql-types'
import { createGetAbsoluteUrl } from '@kijiji/seo/utils/createGetAbsoluteUrl'
import qs from 'query-string'
import xss from 'xss'

import { APP_PEN_DYNAMIC_LINK_PARAMETERS } from '@/constants/appPen'
import { REGEX_PATTERN } from '@/constants/regex'
import { SYSTEM_MESSAGES } from '@/constants/systemMessages'
import { replaceEncodedWhitespaceWithPlusSign } from '@/domain/urls'
import { type AppPenModalType } from '@/types/appPenetrationCampaign'
import { isServer } from '@/utils/isSSR'
import { isUserAgentAndroid, isUserAgentIos } from '@/utils/userAgent'

export const REDIRECT_CONSUMER_FLOWS = [
  'login',
  'logout',
  'register',
  'password-reset',
  'password-forgot',
  'confirm-email',
  'signup',
  'sso',
] as const

type RedirectConsumerFlow = (typeof REDIRECT_CONSUMER_FLOWS)[number]
type QueryParameters = { [key: string]: string | string[] | boolean | number | undefined }
type UserDevice = 'Android' | 'iOS'

export const stringifyUrl = ({ url = '/', query }: { url?: string; query?: QueryParameters }) =>
  qs.stringifyUrl({ url, query })

export const sanitizeUrl = (url?: string) => {
  if (!url) {
    return undefined
  }

  return xss(url)
}

/**
 * Verifies that a Consumer Redirect flow is recognized and valid
 * @param url url string to pass through the regex check
 */
export const isValidRedirectConsumerFlow = (flow?: string): flow is RedirectConsumerFlow => {
  return !!flow && REDIRECT_CONSUMER_FLOWS.includes(flow as RedirectConsumerFlow)
}

/**
 * Verifies that a url is encoded
 * @param url url string to pass through the regex check
 */
export const isEncodedUrl = (url: string) => url.match(REGEX_PATTERN.IS_ENCODED_URL)

export const stripAuthSystemMessagesFromUrl = (url: string) => {
  const { url: parsedUrl, query } = qs.parseUrl(url)

  Object.keys(query).forEach((key) => {
    if (SYSTEM_MESSAGES[key]) {
      delete query[key]
    }
  })

  return qs.stringifyUrl({ url: parsedUrl, query })
}

/**
 * Constructs a redirect url for a flow the consumer will accept (eg. CIS/Auth flows)
 * @param flow flow must be registered in REDIRECT_CONSUMER_FLOWS
 * @param redirect redirect url to append as query param, will be encoded if needed
 * @param extraQueryParams any additional query params to append to url
 */
export const getRedirectConsumerUrl = (
  flow: RedirectConsumerFlow,
  redirect?: string,
  extraQueryParams?: QueryParameters
) => {
  if (redirect) redirect = stripAuthSystemMessagesFromUrl(redirect)

  const query = {
    ...(redirect
      ? { redirect: isEncodedUrl(redirect) ? decodeURIComponent(redirect) : redirect }
      : {}),
    ...extraQueryParams,
  }

  return stringifyUrl({
    url: `${location.origin}/api/consumer/${flow}`,
    query,
  })
}

/**
 * Helper function to encode a safe kijiji redirect and appends the query params, defaults to homepage
 * @param decodedUrl decoded url
 * @param query additional query string key value pairs to append
 *
 */
export const encodeRedirectUrl = (decodedUrl = '/', query: QueryParameters = {}) => {
  return encodeURIComponent(stringifyUrl({ url: decodedUrl, query }))
}

/**
 * Helper function to decode a safe kijiji redirect and appends the query params, defaults to homepage
 * @param encodedUrl encoded url from redirect query string parameter
 * @param query additional query string key value pairs to append
 *
 */
export const decodeRedirectUrl = (
  encodedUrl: string | string[] | undefined,
  query: QueryParameters = {}
) => {
  const decodedRedirect = encodedUrl && decodeURIComponent(encodedUrl.toString())
  return stringifyUrl({ url: decodedRedirect || '/', query })
}

export const getSecondAndTopLevelDomainOfUrl = (url: string) => {
  const splitDomains = url.split('.')

  if (splitDomains.length === 1) {
    return 'localhost'
  }
  const lastIndex = splitDomains.length - 1
  const domainHasPathParams = splitDomains[lastIndex].indexOf('/')

  if (domainHasPathParams >= 0) {
    splitDomains[lastIndex] = splitDomains[lastIndex].substring(0, domainHasPathParams)
  }

  return splitDomains.slice(-2).join('.')
}

export const validateRedirectUrl = (
  redirectUrl: string,
  redirectUrlDomainWhitelist: string
): string => {
  /**
   * Per: https://nodejs.org/docs/latest-v16.x/api/url.html#new-urlinput-base
   *
   * If arg1 is a relative URL, then arg2 will be used to fill out the hostname and protocol.
   * If arg2 is an absolute URL, then arg2 will be ignored.
   */
  const redirect = new URL(redirectUrl, getBaseUrl())

  if (validateRedirectDomain(redirect, redirectUrlDomainWhitelist)) {
    return redirect.toString()
  } else {
    return getBaseUrl() + '/'
  }
}

export const validateRedirectDomain = (redirect: URL, redirectUrlDomainWhitelist: string) => {
  const domainListArray = redirectUrlDomainWhitelist
    .split('+')
    .map((domain) => getSecondAndTopLevelDomainOfUrl(domain))

  return domainListArray.includes(getSecondAndTopLevelDomainOfUrl(redirect.hostname))
}

/**
 * Returns the base url of the current environment using window if available. Window is preferred over
 * our env value on the client to avoid CORS issues when our code is running on multiple domains. We don't do this in tests
 * as window.location is mocked by jest doesn't have the same behaviour as the browser.
 */
export const getBaseUrl = () => {
  if (isServer() || process.env.NODE_ENV === 'test') {
    const webAppUrl = process.env.NEXT_PUBLIC_WEBAPP_URL

    if (!webAppUrl) {
      throw new Error('NEXT_PUBLIC_WEBAPP_URL is not defined')
    }
    return webAppUrl
  } else {
    return window.location.origin
  }
}

/**
 * Helper function that constructs an absolute URL using a combination of the local environment variable and relative resource path
 * @param relativePath resource path relative to public folder
 *
 */
export const getAbsoluteUrl = createGetAbsoluteUrl(
  validateRedirectDomain,
  getBaseUrl,
  process.env.REDIRECT_DOMAIN_WHITELIST ?? ''
)

/**
 * Helper function that returns a Firebase compatible deep link
 * @param relativePath resource path relative to the public folder (including the leading '/')
 *
 */
export const getDeepLink = (relativePath: string) => {
  return `https://www.kijiji.ca${relativePath}`
}

/**
 * Helper function that returns a manually constructed dynamic Firebase link
 * @param deepLink the deep link you would like Firebase to redirect the user to (i.e. "https://kijiji.ca/b-real-estate/c34")
 *  @param device the device used to browse Kijiji
 *
 */
export const getFirebaseLink = (deepLink: string, device: UserDevice, type: AppPenModalType) => {
  let deviceParameters

  if (device === 'Android') {
    deviceParameters = qs.stringify(APP_PEN_DYNAMIC_LINK_PARAMETERS[type].android)
  } else if (device === 'iOS') {
    deviceParameters = qs.stringify(APP_PEN_DYNAMIC_LINK_PARAMETERS[type].ios)
  }

  const dynamicUrl = `https://kijiji.page.link/?link=${deepLink}&${deviceParameters}`
  return dynamicUrl
}

/**
 * Helper function that composes a dynamic Firebase link
 * @param userAgent the user agent string supplied by the user's browser (i.e. Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/114.0.0.0 Safari/537.36)
 * @param customPath if the mobile app page path does not match the webapp page path, we must pass a custom path. For example, in webapp the registration page path is /register, in the mobile app, the registration page path is /t-user-registration.html. /t-user-registration.html must be passed as the customPath parameter.
 * @param type App pen type definition
 */
export const getMobileLink = (type: AppPenModalType, userAgent?: string, customPath?: string) => {
  if (!userAgent) return '/'

  const relativePath = customPath
    ? customPath
    : `${window.location.pathname}${window.location.search}`

  const deepLink = getDeepLink(relativePath)

  if (isUserAgentAndroid(userAgent)) {
    return getFirebaseLink(deepLink, 'Android', type)
  }

  if (isUserAgentIos(userAgent)) {
    return getFirebaseLink(deepLink, 'iOS', type)
  }

  return '/'
}

/**
 * Appends radial location information to the given URL.
 * @param url - The URL to append the location information to.
 * @param locationArea - The location area object containing the radius, address, latitude, and longitude.
 * @returns The modified URL with the appended location information.
 */
export const appendRadialLocationToUrl = (url: string, locationArea?: LocationArea | null) => {
  if (!locationArea) {
    return url
  }

  const { url: parsedUrl, query } = qs.parseUrl(url)

  let newUrl = qs.stringifyUrl({
    url: parsedUrl,
    query: {
      ...query,
      radius: locationArea.radius.toFixed(1),
      ...(locationArea.address && { address: locationArea.address }),
      ll: `${locationArea.latitude},${locationArea.longitude}`,
    },
  })

  newUrl = replaceEncodedWhitespaceWithPlusSign(newUrl)

  return newUrl
}

/**
 * Returns the YouTube embed URL for the given video ID to be used in an iframe.
 */
export const getYouTubeEmbedUrl = (videoId: string) => `https://www.youtube.com/embed/${videoId}`

/**
 * Removes the free keyword from the URL, used specifically for the popular near you section
 *
 * @param url - The URL to remove the keyword from.
 * @param keyword - The keyword to remove from the URL.
 * @returns The modified URL with the keyword removed.
 */
export function removeFreeKeywordFromUrl(url: string, keyword: string): string {
  const urlParts = url.split('/')
  const keywordIndex = urlParts.findIndex((part) => part.toLowerCase() === keyword.toLowerCase())

  if (keywordIndex === -1) return url

  // Remove the keyword
  urlParts.splice(keywordIndex, 1)

  // Remove kxxx if present in the last segment
  const lastSegment = urlParts[urlParts.length - 1]

  if (lastSegment.match(/k\d+/)) {
    urlParts[urlParts.length - 1] = lastSegment.replace(/k\d+/g, '')
  }

  return urlParts.join('/')
}

/**
 * Attaches or overwrites the price type in the URL.
 *
 * @param url - The URL to modify.
 * @param priceType - The price type to attach or overwrite.
 * @returns The modified URL with the price type attached or overwritten.
 *
 * @example
 * ```typescript
 * const url = 'https://kijiji.ca/b-city-of-toronto/l1700273';
 * const modifiedUrl = attachPriceTypeToUrl(url, 'free');
 * console.log(modifiedUrl); // "https://kijiji.ca/b-city-of-toronto/l1700273?price-type=free"
 * ```
 */
export function attachPriceTypeToUrl(
  url: string,
  priceType: 'free' | 'contact' | 'swapTrade'
): string {
  const { url: parsedUrl, query } = qs.parseUrl(url)

  // Update the query with the new price type
  const updatedQuery = {
    ...query,
    'price-type': priceType,
  }

  return qs.stringifyUrl({ url: parsedUrl, query: updatedQuery })
}

/**
 * Attaches or overwrites the offer type in the URL.
 *
 * @param url - The URL to modify.
 * @param offerType - The offer type to attach or overwrite ("wanted" or "offer").
 * @returns The modified URL with the offer type attached or overwritten.
 *
 * @example
 * ```typescript
 * const url = 'https://kijiji.ca/b-city-of-toronto/l1700273';
 * const modifiedUrl = attachOfferTypeToUrl(url, 'wanted');
 * console.log(modifiedUrl); // "https://kijiji.ca/b-city-of-toronto/l1700273?ad=wanted"
 * ```
 */
export function attachOfferTypeToUrl(url: string, offerType: 'wanted' | 'offer'): string {
  const { url: parsedUrl, query } = qs.parseUrl(url)

  // Update the query with the new offer type
  const updatedQuery = {
    ...query,
    ad: offerType,
  }

  return qs.stringifyUrl({ url: parsedUrl, query: updatedQuery })
}
