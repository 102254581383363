import { type HttpOptions } from '@apollo/client'
import { createUploadLink } from 'apollo-upload-client'

import { getAnvilUrl } from '@/lib/apollo/utils/getAnvilUrl'

const clientHttpLink = () => {
  const url = getAnvilUrl()

  const enhancedFetch: HttpOptions['fetch'] = async (url, init) => {
    return fetch(url, {
      ...init,
      headers: {
        ...init?.headers,
        origin: process.env.NEXT_PUBLIC_WEBAPP_URL ?? '',
      },
    })
  }

  return createUploadLink({
    uri: url,
    credentials: 'include',
    fetch: enhancedFetch,
    headers: {
      'Apollo-Require-Preflight': 'true',
    },
  })
}

export default clientHttpLink
