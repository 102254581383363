import { type CookieRegistry, type PrefixedCookieRegistry } from '@/constants/cookieRegistry'
import { getCookieSubDomain } from '@/utils/cookies/getCookieSubDomain'
import { MAX_INTEGER_SAFE_MAX_AGE } from '@/utils/cookies/getMaxSafeAgeExpiry'

/**
 * Creates a cookie with optional expiry and domain settings.
 *
 * @param {CookieRegistry} key - The name of the cookie.
 * @param {string} value - The value of the cookie.
 * @param {Object} options - Additional cookie options.
 * @param {number} [options.expiryInDays] - Number of days until the cookie expires. If both `expiryInDays` and `maxAge` are set, `maxAge` takes precedence.
 * @param {number} [options.maxAge] - The maximum age of the cookie in seconds. If larger than 2147483647, it will be capped at this maximum.
 * @param {boolean} [options.useSubDomainSafe=true] - Whether to restrict the cookie to the subdomain.
 * @param {boolean} [options.fullDomainOnProd=false] - Whether to use the full domain when setting the cookie in production (e.g., "kijiji.ca").
 */
export const createCookie = (
  key: CookieRegistry | PrefixedCookieRegistry,
  value: string,
  {
    expiryInDays,
    maxAge,
    secure,
    useSubDomainSafe = true,
    fullDomainOnProd = false,
  }: {
    expiryInDays?: number
    maxAge?: number
    secure?: boolean
    useSubDomainSafe?: boolean
    fullDomainOnProd?: boolean
  } = {}
) => {
  let domain = ''
  const hostname = global.location.hostname
  const isProdAndShouldUseFullDomain = hostname.includes('kijiji.ca') && fullDomainOnProd

  if (useSubDomainSafe && !isProdAndShouldUseFullDomain) {
    const subDomainCookie = getCookieSubDomain(hostname)
    domain = ` domain=${subDomainCookie};`
  }

  // Check if the value needs encoding by looking for any characters that would require encoding
  const valueIsEncoded = /%[0-9A-Fa-f]{2}/.test(value)
  const cookieValue = valueIsEncoded ? value : encodeURIComponent(value)

  let cookieString = `${key}=${cookieValue};`

  if (maxAge) {
    // Cap max-age at 2147483647 as this is the maximum allowed value (int32)
    const _maxAge = maxAge > MAX_INTEGER_SAFE_MAX_AGE ? MAX_INTEGER_SAFE_MAX_AGE : maxAge
    cookieString += ` max-age=${_maxAge};`
  }

  if (expiryInDays) {
    const date = new Date()
    date.setTime(date.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
    cookieString += ` expires=${date.toUTCString()};`
  }

  if (secure) {
    cookieString += ' Secure;'
  }

  cookieString += `${domain} path=/;`
  document.cookie = cookieString
}
