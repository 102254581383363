import { type GaEvent } from '@/lib/ga/constants/gaEvent'
import { pushToDataLayer } from '@/lib/ga/utils/gatDatalayerActions'

export type CoreEventArgs = {
  action: GaEvent
  label?: string
}

export type TrackEventArgs = CoreEventArgs & {
  name?: string
  nonInteraction?: number
  customParameters?: Record<string, unknown> & {
    session_level_test_group?: string // This is used when doing experiments
  }
}

/**
 * Tracks an event by pushing it to the data layer.
 *
 * @param {TrackEventArgs} options - The options for tracking the event.
 * @param {GaEvent} options.action - The action or event name to be tracked.
 * @param {Record<string, unknown>} [options.customParameters] - Additional custom parameters to be included.
 * @param {string} [options.label=''] - An optional label for the event.
 * @param {string} [options.name] - An optional name for the event (defaults to action if not provided).
 * @param {number} [options.nonInteraction=0] - Indicates if the event is a non-interaction event.
 */
export const trackEvent = ({
  action,
  customParameters,
  label = '',
  name,
  nonInteraction = 0,
}: TrackEventArgs) => {
  pushToDataLayer({
    event: action,
    event_name: name || action,
    eventLabel: label,
    uaNonInt: nonInteraction,
    ...customParameters,
  })
}
/**
 * Tracks a page view by pushing it to the data layer.
 *
 * @param {string} url - The URL of the page being viewed.
 */
export const trackPageView = (url: string) => {
  pushToDataLayer({ event: 'VirtualPageView', virtualPageUrl: url }, 0, true)
}
