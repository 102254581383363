import { ThemeProps } from '@kijiji/theme'
import { FC, HTMLAttributes } from 'react'
import { useTheme } from 'styled-components'

import { ResponsiveProp, StyledAs } from '@/ui/typings/helpers'

import { HeadlineTextBase } from './styled'

export type HeadlineSize = keyof ThemeProps['typography']['headline']
export type HeadlineWeight = keyof ThemeProps['fontWeight']

export type HeadlineTextProps = {
  /**
   * The type of element you want text to be rendered in
   * @default 'h1'
   */
  as?: StyledAs
  /**
   * Define custom heading color
   */
  color?: string
  /**
   * Defined alignment of heading
   */
  textAlign?: 'start' | 'end' | 'left' | 'center' | 'right' | 'justify'
  /**
   * Define heading size within typography theme
   * @default 'small'
   */
  size?: ResponsiveProp<HeadlineSize>
  /**
   * Defined weight of text
   * @default 'medium'
   */
  weight?: ResponsiveProp<HeadlineWeight>
} & HTMLAttributes<HTMLHeadingElement>

export const HeadlineText: FC<HeadlineTextProps> = ({
  color,
  size = 'small',
  textAlign = 'start',
  weight = 'medium',
  ...rest
}: HeadlineTextProps) => {
  const { colors } = useTheme()
  return (
    <HeadlineTextBase
      $color={color ?? colors.grey.primary}
      $weight={weight}
      $size={size}
      $textAlign={textAlign}
      {...rest}
    />
  )
}
