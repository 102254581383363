export type TypographyKeys = keyof typeof typography

export const fontFamily = {
  general: 'Larsseit, Arial, sans-serif',
}

export const fontWeight = {
  regular: '400',
  medium: '500',
  semiBold: '700',
  bold: '800',
}

export const typography = {
  headline: {
    colossal: {
      fontSize: '6.0rem',
      lineHeight: '7.5rem',
    },
    giant: {
      fontSize: '4.0rem',
      lineHeight: '5.0rem',
    },
    xLarge: {
      fontSize: '2.8rem',
      lineHeight: '3.6rem',
    },
    large: {
      fontSize: '2.2rem',
      lineHeight: '2.6rem',
    },
    medium: {
      fontSize: '1.8rem',
      lineHeight: '2.2rem',
    },
    small: {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
    },
    xSmall: {
      fontSize: '1.6rem',
      lineHeight: '2.2rem',
    },
  },
  body: {
    xLarge: {
      fontSize: '2.2rem',
      lineHeight: '2.6rem',
    },
    large: {
      fontSize: '1.8rem',
      lineHeight: '2.6rem',
    },
    medium: {
      fontSize: '1.6rem',
      lineHeight: '2.4rem',
    },
    small: {
      fontSize: '1.4rem',
      lineHeight: '1.8rem',
    },
    xSmall: {
      fontSize: '1.2rem',
      lineHeight: '1.6rem',
    },
    xxSmall: {
      fontSize: '1rem',
      lineHeight: '1rem',
    },
  },
}
