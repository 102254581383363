import { theme } from '@kijiji/theme'
import { createGlobalStyle } from 'styled-components'

/**
 * Global fonts are set using next/font in _app.tsx
 * automatically determines & adjusts fallback fonts to minimize layout shift
 * */
export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: ${theme.spacing.default};
    min-height: 100vh;
  }

  button {
    cursor: pointer;
    background: none;
    border: none;
  }

  ul {
    list-style: none;
  }

  a {
    cursor: pointer;
  }

  /* Remove recaptcha badge on all NWA */
  .grecaptcha-badge { 
    visibility: hidden;
  }

  /* Hide arrow-related styles in Google Maps InfoWindow */
  .gm-style-iw-tc,
  .gm-style-iw-tc:before,
  .gm-style-iw-tc::after {
    display: none;
 }
`
