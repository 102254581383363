import { setContext } from '@apollo/client/link/context'

import { HEADERS } from '@/constants/headers'
import { type LanguageKey } from '@/domain/locale'
import { type RequestHeaders } from '@/lib/apollo/apolloClient'
import { serializeCookies } from '@/utils/cookies/serializeCookies'

const contextLink = (languageKey: LanguageKey, requestHeaders?: RequestHeaders) => {
  return setContext(async (_, previousContext) => {
    const { headers = {}, cookies } = requestHeaders || {}
    const cookieHeader = cookies ? { Cookie: serializeCookies(cookies) } : {}

    const newContext = {
      headers: {
        ...headers,
        ...(previousContext.headers || {}),
        'Accept-Language': languageKey,
        ...cookieHeader,
      },
    }

    // if the incoming request does not have experiments context added, return the new context constructing experiments header string
    if (!previousContext.experiments) {
      return newContext
    }

    const experimentsHeaderValue = Object.entries(previousContext.experiments)
      .map(([trackingKey, value]) => {
        const testGroup = typeof value === 'boolean' ? (value ? 1 : 0) : value
        return `${trackingKey.toUpperCase()}=${testGroup};`
      })
      .join('')

    const experimentsHeader = experimentsHeaderValue
      ? { [HEADERS.X_EXPERIMENTS]: `${experimentsHeaderValue}` }
      : {}

    const { headers: newHeaders, ...restContext } = newContext

    return {
      ...restContext,
      headers: {
        ...newHeaders,
        ...experimentsHeader,
      },
    }
  })
}

export default contextLink
