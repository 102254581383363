import { ThemeProps } from '@kijiji/theme'
import styled from 'styled-components'

import { KEYBOARD_ONLY_OUTLINE } from '@/ui/constants/commonStyles'
import { applyResponsiveStyle } from '@/ui/helpers/applyResponsiveStyle'
import { styleFromTypography } from '@/ui/helpers/styleFromTypography'
import { RequiredPickMap } from '@/ui/typings/helpers'

import { TextLinkProps } from '.'
import { TextLinkSize } from './TextLink'

const colorFromType = (theme: ThemeProps) => ({
  primary: theme.colors.green.dark1,
  secondary: theme.colors.purple.primary,
  tertiary: theme.colors.grey.light1,
})

type TextLinkBaseProps = RequiredPickMap<
  TextLinkProps,
  'hasUnderline' | 'size' | 'variant' | 'weight'
>

const getResponsiveSize = (value: TextLinkSize, theme: ThemeProps) =>
  styleFromTypography(theme.typography.body[value])

export const textLinkBaseStyle = (
  theme: ThemeProps,
  { $hasUnderline, $size, $variant, $weight }: TextLinkBaseProps
) => `
  ${KEYBOARD_ONLY_OUTLINE}
  ${applyResponsiveStyle($size, theme, getResponsiveSize)}

  color: ${colorFromType(theme)[$variant]};
  cursor: pointer;
  font-family: inherit;
  font-weight: ${theme.fontWeight[$weight]};
  text-decoration: ${$hasUnderline ? 'underline' : 'none'};

  &:hover,
  &:focus,
  &:active {
    text-decoration: ${$hasUnderline ? 'none' : 'underline'};
  }
`

export const TextLinkBase = styled.a<TextLinkBaseProps>(
  ({ $hasUnderline, $size, $variant, $weight, theme }) => `
  ${textLinkBaseStyle(theme, { $hasUnderline, $size, $variant, $weight })}
`
)
