import { type Event, type EventHint } from '@sentry/types'

import { normalizeErrorContext } from '@/lib/sentry/normalizeErrorContext'

export const beforeSend = (event: Event, hint: EventHint): Event => {
  const error = hint.originalException as Error | string | null

  if (event && error && (!event.fingerprint || event.fingerprint.length === 0)) {
    event.fingerprint = event.fingerprint ?? []

    if (error instanceof Error) {
      const { name, message, extra = {} } = normalizeErrorContext(error)

      event.fingerprint.push(name)
      event.fingerprint.push(message)
      event.extra = { ...event.extra, ...extra }
    } else if (typeof error === 'string') {
      event.fingerprint.push(error)
    }
  }

  return event
}
