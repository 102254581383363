import { AnchorHTMLAttributes, ButtonHTMLAttributes, forwardRef } from 'react'

import { ResponsiveProp, StyledAs } from '@/ui/typings/helpers'

import { Loading } from '../loading'
import { ButtonBase } from './styled'

export type ButtonSizes = 'medium' | 'small' | 'compact'

export type ButtonShapes = 'rectangular' | 'pill'

type LoadingProps =
  | { isLoading?: boolean }
  | {
      isLoading?: false
      loadingLabel?: never
    }
  | {
      isLoading: true
      loadingLabel: string
    }

type AsButtonProps = {
  as?: 'button' | StyledAs
} & ButtonHTMLAttributes<HTMLButtonElement>

type AsLinkProps = {
  as: 'a'
} & AnchorHTMLAttributes<HTMLAnchorElement>

export type ButtonProps = {
  /**
   * Specify button taking full with of container
   * @default false
   */
  isFullWidth?: boolean
  /**
   * Specify loading button state
   * @default false
   */
  isLoading?: boolean
  /**
   * Specify whether the button is selected
   * @default false
   */
  isSelected?: boolean
  /**
   * Specify loading button label required only when isLoading is true
   */
  loadingLabel?: string
  /**
   * Specify button shape
   * @default "rectangular"
   */
  shape?: ButtonShapes
  /**
   * Specify button size
   * @default "medium"
   */
  size?: ResponsiveProp<ButtonSizes>
  /**
   * Specify gap of content inside button
   */
  gap?: string
  /**
   * Specify button style variant
   * @default "primary"
   */
  variant?:
    | 'primary'
    | 'primaryDark'
    | 'primaryNoBorder'
    | 'secondary'
    | 'secondaryDark'
    | 'tertiary'
    | 'tonal'
    | 'subtle'
} & LoadingProps &
  (AsButtonProps | AsLinkProps)

const getLoadingVariant = (variant: ButtonProps['variant']) => {
  if (variant === 'primary') return 'light'
  if (variant === 'secondary' || variant === 'subtle' || variant === 'tonal')
    return 'primary'

  return 'secondary'
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      as = 'button',
      children,
      isFullWidth = false,
      isLoading = false,
      isSelected = false,
      loadingLabel = '',
      size = 'medium',
      shape = 'rectangular',
      variant = 'primary',
      'aria-label': ariaLabel,
      onClick,
      ...rest
    }: ButtonProps,
    ref
  ) => {
    return (
      <ButtonBase
        $as={as}
        $isFullWidth={isFullWidth}
        $isLoading={isLoading}
        $isSelected={isSelected}
        $size={size}
        $shape={shape}
        $variant={variant}
        $gap={rest.gap}
        aria-busy={isLoading}
        aria-label={isLoading ? loadingLabel : ariaLabel}
        as={as}
        onClick={!isLoading ? onClick : undefined}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <Loading size="small" variant={getLoadingVariant(variant)} />
        ) : (
          children
        )}
      </ButtonBase>
    )
  }
)
