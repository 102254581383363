export const ErrorStateLoad = 'ErrorStateLoad'
export const ResultsBrowse = 'ResultsBrowse'
export const ResultsSearch = 'ResultsSearch'
export const SearchAttempt = 'SearchAttempt'
export const SearchBegin = 'SearchBegin'
export const SelectPromotion = 'select_promotion'
export const ViewPromotion = 'view_promotion'
export const TopAdLearnMoreClick = 'TopAdLearnMoreClick'

export type SearchPageType = typeof ResultsSearch | typeof ResultsBrowse
